import React from "react";
import { HiArrowSmallRight } from "react-icons/hi2";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./Choose.css";
import ChooseImg from "../../img/why-choose-right-two.jpg";

const Choose = () => {
  return (
    <div className="choose">
      <div className="choose-container">
        <div className="choose-content">
          <div className="choose-header">
            <span>
              <HiArrowSmallRight />
              Why Choose Us
            </span>
            <h2>Web Design Company That You Can Trust</h2>
          </div>
          <div className="choose-cards">
            <div className="choose-box">
              <div>
                <span>
                  <MdKeyboardArrowRight />
                </span>
                <span>Competitive rates</span>
              </div>
              <div>
                <p>
                  We use strategic marketing tactics that have been proven
                  programming
                </p>
              </div>
            </div>
            <div className="choose-box">
              <div>
                <span>
                  <MdKeyboardArrowRight />
                </span>
                <span>Premium Quality Development</span>
              </div>
              <div>
                <p>
                Delivering top-notch development solutions with an emphasis on quality and excellence.
                </p>
              </div>
            </div>
            <div className="choose-box">
              <div>
                <span>
                  <MdKeyboardArrowRight />
                </span>
                <span>No contracts needed</span>
              </div>
              <div>
                <p>
                You can increase, pause or stop our services at any time leaving
                </p>
              </div>
            </div>
            <div className="choose-box">
              <div>
                <span>
                  <MdKeyboardArrowRight />
                </span>
                <span>Retina Ready & Flexible</span>
              </div>
              <div>
                <p>
                Devices show more pixels square inch resulting sharperes images Content
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="choose-image">
          <img src={ChooseImg} alt="" />
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
