import React from 'react';
import './ProfessionalsTeam.css'; // Import CSS for styling
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa'; // Import icons from react-icons library
import memeber from "../img/member6.jpg"


const ProfessionalsTeam = () => {
  return (
    <div className="professionals-team">
        <span>Team Members</span>
      <h1>Meet Our Professionals Team</h1>
      <div className="team-members">
        <div className="team-member">
          <div className="member-image">
            <img src={memeber} alt="Team Member 1" />
          </div>
          <h3>John Doe</h3>
          <p>Position / Role</p>
          <div className="social-links">
            <a href="twitter_link"><FaTwitter /></a>
            <a href="linkedin_link"><FaLinkedin /></a>
            <a href="github_link"><FaGithub /></a>
          </div>
          {/* Add additional information about the team member */}
        </div>
        <div className="team-member">
          <div className="member-image">
            <img src={memeber} alt="Team Member 2" />
          </div>
          <h3>Jane Smith</h3>
          <p>Position / Role</p>
          <div className="social-links">
            <a href="twitter_link"><FaTwitter /></a>
            <a href="linkedin_link"><FaLinkedin /></a>
            <a href="github_link"><FaGithub /></a>
          </div>
          {/* Add additional information about the team member */}
        </div>
        <div className="team-member">
          <div className="member-image">
            <img src={memeber} alt="Team Member 2" />
          </div>
          <h3>Jane Smith</h3>
          <p>Position / Role</p>
          <div className="social-links">
            <a href="twitter_link"><FaTwitter /></a>
            <a href="linkedin_link"><FaLinkedin /></a>
            <a href="github_link"><FaGithub /></a>
          </div>
          {/* Add additional information about the team member */}
        </div>
        <div className="team-member">
          <div className="member-image">
            <img src={memeber} alt="Team Member 2" />
          </div>
          <h3>Jane Smith</h3>
          <p>Position / Role</p>
          <div className="social-links">
            <a href="twitter_link"><FaTwitter /></a>
            <a href="linkedin_link"><FaLinkedin /></a>
            <a href="github_link"><FaGithub /></a>
          </div>
          {/* Add additional information about the team member */}
        </div>
        <div className="team-member">
          <div className="member-image">
            <img src={memeber} alt="Team Member 2" />
          </div>
          <h3>Jane Smith</h3>
          <p>Position / Role</p>
          <div className="social-links">
            <a href="twitter_link"><FaTwitter /></a>
            <a href="linkedin_link"><FaLinkedin /></a>
            <a href="github_link"><FaGithub /></a>
          </div>
          {/* Add additional information about the team member */}
        </div>
        <div className="team-member">
          <div className="member-image">
            <img src={memeber} alt="Team Member 2" />
          </div>
          <h3>Jane Smith</h3>
          <p>Position / Role</p>
          <div className="social-links">
            <a href="twitter_link"><FaTwitter /></a>
            <a href="linkedin_link"><FaLinkedin /></a>
            <a href="github_link"><FaGithub /></a>
          </div>
          {/* Add additional information about the team member */}
        </div>
        <div className="team-member">
          <div className="member-image">
            <img src={memeber} alt="Team Member 2" />
          </div>
          <h3>Jane Smith</h3>
          <p>Position / Role</p>
          <div className="social-links">
            <a href="twitter_link"><FaTwitter /></a>
            <a href="linkedin_link"><FaLinkedin /></a>
            <a href="github_link"><FaGithub /></a>
          </div>
          {/* Add additional information about the team member */}
        </div>
        {/* Add more team members as needed */}
      </div>
    </div>
  );
};

export default ProfessionalsTeam;
