import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import "./Footer.css"
import letWork from "../../img/lets-work.png"
import { MdArrowOutward } from 'react-icons/md';
import Logo from "../../img/Logo.png"
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-container">
            <div className="footer-logo">
                <div className="img">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="social">
                    <a href="https://www.instagram.com/"><FaInstagram /> Instagram</a>
                    <a href="https://www.facebook.com/"><FaFacebook /> Facebook</a>
                    <a href="https://www.linkedin.com/company/"><FaLinkedin /> Linkedin</a>
                </div>
            </div>
            <div className="medium-conatiner-footer">
                <div className="medium-conatiner-footer-first">
                    <span>
                        <img src={letWork} alt="" />
                        Let’s Work Together
                    </span>
                    <div>
                        <a href="mailto:infotechdreamviewer@gmail.com"><span>infotechdreamviewer@gmail.com</span> <MdArrowOutward/></a>
                        <a href="tel:9142050882"><span>+91 9142050882</span> <MdArrowOutward/></a>
                    </div>
                </div>
                <div className="medium-conatiner-footer-second">
                        <div className='quick-Links'>
                            <h3>Quick Links</h3>
                            <ul>
                                <li><Link href="/">Home</Link></li>
                                <li><Link href="/about">About</Link></li>
                                <li><Link href="/services">Servies</Link></li>
                                <li><a href="">Portfolio</a></li>
                                <li><a href="/contact">Contact Us?</a></li>
                            </ul>
                        </div>
                </div>
            </div>
            <div className="copy">
                    <p>Copyright © 2024 Dream Infotech. All rights reserved.</p>
                </div>
        </div>
    </div>
  )
}

export default Footer