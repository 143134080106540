import React from 'react';
import FaqImage from "../../img/faq.png";
import { HiArrowSmallRight } from "react-icons/hi2";
import "./Faq.css"

const Faq = ({faqQuestions}) => {
  // Define an array of FAQ questions
 

  return (
    <div className='faq'>
      <div className="faq-container">
        <div className='faq-img'>
          <img src={FaqImage} alt="faq-image" />
        </div>
        <div className='faq-content'>
          <div className="faq-header">
            <span><HiArrowSmallRight/> Asked Questions</span>
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="faq-questions">
            {faqQuestions.map((faq, index) => (
              <div key={index} className="faq-question">
                <details>
                  <summary>{faq.question}</summary>
                  <p>{faq.answer}</p>
                </details>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
