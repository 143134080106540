// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial {
    margin-bottom: 20px;
    /* border: 1px solid red; */
  }
  
  .testimonial-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 80px;
    gap: 20px;
  }
  
  .left-content {
    margin-right: 20px;
  }
  
  
 .testimonial-content {
  /* border: 1px solid; */
  width: 50%;
  display: flex;
  margin: auto;
  flex-direction: column;
  gap: 20px;
 }

 .testimonial-message {
  font-size: 30px;
  font-weight: 400;
 }

 @media screen and (max-width: 768px) {

  .testimonial-container {
    flex-direction: column;
    margin:  0 40px;
  }

  .testimonial-content {
    width: 100%;
    padding: 20px;
  }

  .testimonial-message {
    font-size: 16px;
  }
 }`, "",{"version":3,"sources":["webpack://./src/components/Testimonial.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,SAAS;EACX;;EAEA;IACE,kBAAkB;EACpB;;;CAGD;EACC,uBAAuB;EACvB,UAAU;EACV,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,SAAS;CACV;;CAEA;EACC,eAAe;EACf,gBAAgB;CACjB;;CAEA;;EAEC;IACE,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,eAAe;EACjB;CACD","sourcesContent":[".testimonial {\n    margin-bottom: 20px;\n    /* border: 1px solid red; */\n  }\n  \n  .testimonial-container {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n    margin: 0 80px;\n    gap: 20px;\n  }\n  \n  .left-content {\n    margin-right: 20px;\n  }\n  \n  \n .testimonial-content {\n  /* border: 1px solid; */\n  width: 50%;\n  display: flex;\n  margin: auto;\n  flex-direction: column;\n  gap: 20px;\n }\n\n .testimonial-message {\n  font-size: 30px;\n  font-weight: 400;\n }\n\n @media screen and (max-width: 768px) {\n\n  .testimonial-container {\n    flex-direction: column;\n    margin:  0 40px;\n  }\n\n  .testimonial-content {\n    width: 100%;\n    padding: 20px;\n  }\n\n  .testimonial-message {\n    font-size: 16px;\n  }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
