// Contact.js

import React from 'react';
import { HiMail, HiPhone } from "react-icons/hi";
import { BiMap } from "react-icons/bi";
import "./Contact.css";

const Contact = () => {
  return (
    <div className='contact-us'>
      <div className="contact-container">
        <div className="contact-details">
          <h2>Contact Details</h2>
          <ul>
            <li><HiMail />infotechdreamviewer@gmail.com</li>
            <li><HiPhone /> +91 8860464098</li>
            <li><BiMap /> Dream viewer Infotech Bhurna road, Opp Chandivilla Acharaj, Bounsi, Banka, Bihar</li>
          </ul>
        </div>
        <div className="contact-form">
          <h2>Contact Form</h2>
          <form>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="4" required></textarea>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div className="contact-map">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.7940578926878!2d87.0160247!3d24.802504499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f0f90052b394ff%3A0xa93743b14b05cfd0!2sAcharaj!5e0!3m2!1sen!2sin!4v1711615818192!5m2!1sen!2sin"
          width="100%"
          height="300"
          style={{ border: 0, borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          allowFullScreen
        />
      </div>
    </div>
  );
}

export default Contact;
