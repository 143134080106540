import React from 'react';
import './WebDevelopmentServicesDetails.css'; 
import { MdCheck } from "react-icons/md";
import ServiceImage from "../img/service-page-about.jpg"
import laef from "../img/leaf.png"
import { HiArrowSmallRight } from "react-icons/hi2";
import Plan from '../components/Plan/Plan';
import Choose from '../components/Choose/Choose';
import Faq from '../components/Faq/Faq';

const HotelManagementSoftware = () => {

    const faqQuestions = [
        {
          question: "What is Hotel Management Software?",
          answer: "Hotel Management Software is a digital solution designed to streamline various operations within a hotel or hospitality business, including reservation management, guest services, billing, housekeeping, and more."
        },
        {
          question: "Why is Hotel Management Software important?",
          answer: "Hotel Management Software automates manual tasks, enhances guest experience, improves operational efficiency, increases revenue, and helps hoteliers make data-driven decisions for better business outcomes."
        },
        {
          question: "What features does your Hotel Management Software offer?",
          answer: "Our Hotel Management Software offers a comprehensive set of features, including reservation management, room allocation, guest check-in/check-out, billing and invoicing, housekeeping management, reporting and analytics, and integration with other systems such as POS (Point of Sale) and accounting software."
        }
        // Add more questions as needed
      ];

    return (
        <div className="services-details-container">
            <div className="services-details-content">
               <div className='services-details-header'>
                    <span>Hotel Management Software</span>
                    <img src={laef} alt="leaf" />
               </div>
               <div className='services-details-main-content'>
                    <div className='services-details-main-content-image'>
                        <img src={ServiceImage} alt="Service About" />
                    </div>
                    <div className='services-details-main-content-box'>
                        <div>
                            <span> <HiArrowSmallRight />What We Offer</span>
                            <h1>Comprehensive Hotel Management Software Solutions</h1>
                        </div>
                        <div>
                            <p>Optimize your hotel operations and elevate guest satisfaction with our advanced Hotel Management Software. From reservation management to guest services, our software is tailored to meet the specific needs of the hospitality industry.</p>
                            <ul>
                                <li><span><MdCheck /></span>Reservation Management</li>
                                <li><span><MdCheck /></span>Room Allocation and Availability</li>
                                <li><span><MdCheck /></span>Guest Check-in/Check-out</li>
                                <li><span><MdCheck /></span>Billing and Invoicing</li>
                                <li><span><MdCheck /></span>Housekeeping Management</li>
                                <li><span><MdCheck /></span>Reporting and Analytics</li>
                                {/* Add more features as needed */}
                            </ul>
                        </div>
                    </div>
               </div>

                 <Plan/>
                 <Choose/>
                 <Faq faqQuestions={faqQuestions}/>
               
            </div>
        </div>
    );
};

export default HotelManagementSoftware;
