// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* About.css */

.about-container {
    padding: 20px;
  }
  
  .about-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-content h1 {
    color: #55e6a5;
  }
  
  .about-content h2 {
    color: #55e6a5;
    margin-top: 40px;
  }
  
  .about-content p {
    color: #333;
    line-height: 1.6;
  }
  
  .about-content ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #333;
  }
  
  .about-content li {
    margin-bottom: 10px;
  }
  
  /* Additional styles as needed */
  `, "",{"version":3,"sources":["webpack://./src/page/About/About.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;IACI,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB;;EAEA,gCAAgC","sourcesContent":["/* About.css */\n\n.about-container {\n    padding: 20px;\n  }\n  \n  .about-content {\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .about-content h1 {\n    color: #55e6a5;\n  }\n  \n  .about-content h2 {\n    color: #55e6a5;\n    margin-top: 40px;\n  }\n  \n  .about-content p {\n    color: #333;\n    line-height: 1.6;\n  }\n  \n  .about-content ul {\n    list-style-type: disc;\n    padding-left: 20px;\n    color: #333;\n  }\n  \n  .about-content li {\n    margin-bottom: 10px;\n  }\n  \n  /* Additional styles as needed */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
