import React from 'react';
import { MdOutlineArrowOutward } from "react-icons/md";
import service1 from "../../img/service1.jpg";
import "./Services.css"; 
import { Link } from 'react-router-dom';
import AppDevelopment from "../../img/Appdevelopment.jpg"
import WebDevelopment from "../../img/Webdevelopment.jpg"
import DigitalMakrkrting from "../../img/DigitalMarketing.jpg"
import ErpSoftware from "../../img/ErpSoftware.jpg"
import GstBill from "../../img/GstBill.jpg"
import HotelManagement from "../../img/HotelManagment.jpg"

const Services = () => {
  return (
    <div className="services">
      <div className="services-container">
        <div className="services-header">
          <span className="popular-services">Popular Services</span>
          <h2>We Provide Amazing IT Services Solutions</h2>
        </div>
        <div className="service-cards">
          <div className="service-card">
            <img src={WebDevelopment} alt="Service" />
            <div className="service-details">
              <span className="service-title">Web Development</span>
              <button className="service-button">
              <Link to="/web-development">
                <MdOutlineArrowOutward />
              </Link>
              </button>
            </div>
          </div>
          <div className="service-card">
            <img src={AppDevelopment} alt="Service" />
            <div className="service-details">
              <span className="service-title">App Development</span>
              <button className="service-button">
                <Link to="/app-development">
                    <MdOutlineArrowOutward />
                </Link>
              </button>
            </div>
          </div>
          <div className="service-card">
            <img src={DigitalMakrkrting} alt="Service" />
            <div className="service-details">
              <span className="service-title">Digital Marketing</span>
              <button className="service-button">
              <Link to={"degital-marketing"}>
              <MdOutlineArrowOutward />
              </Link>
              </button>
            </div>
          </div>
          <div className="service-card">
            <img src={ErpSoftware} alt="Service" />
            <div className="service-details">
              <span className="service-title">Erp Software</span>
              <button className="service-button">
              <Link to={"/erp-software"}>
              <MdOutlineArrowOutward />
              </Link>
              </button>
            </div>
          </div>
          <div className="service-card">
            <img src={GstBill} alt="Service" />
            <div className="service-details">
              <span className="service-title">GST Bill Software</span>
              <button className="service-button">
              <Link to={"/gst-bill"}>
                <MdOutlineArrowOutward />
              </Link>
              </button>
            </div>
          </div>
          <div className="service-card">
            <img src={HotelManagement} alt="Service" />
            <div className="service-details">
              <span className="service-title">Hotel Management Software</span>
              <button className="service-button">
              <Link to={"/hotel-management"}>
              <MdOutlineArrowOutward />
              </Link>
              </button>
            </div>
          </div>
         
        
          {/* Add more service cards as needed */}
        </div>
      </div>
    </div>
  );
};

export default Services;
